const latinpatterns = ['latin', 'certificatelatin'];
// function getSanitizedStr(str) {
//   const escapableChars = [
//     '\\',
//     ' ',
//     '-',
//     '!',
//     '$',
//     '^',
//     '*',
//     '(',
//     ')',
//     '[',
//     ']',
//     '{',
//     '}',
//     '.',
//     '?',
//     '+',
//     ':',
//     '=',
//     '/',
//     '<',
//     '>',
//     '|',
//     ',',
//     '#',
//     '@',
//   ];
//   let inner = str;
//   escapableChars.forEach(c => {
//     inner = inner.replace(c, `\\${c}`);
//   });
//   inner = inner.replace(' ', '\\s');
//   return inner;
// }

export default class HtmlData {
  constructor(args) {
    this.value = args.value || '';
    this.placeholder = args.placeholder || '';
    this.readonly = args.readonly && args.readonly === 'yes';
    this.required = args.required && args.required === 'yes';
    if (args.check) this.check = args.check;
    // if (args.mask) this.mask = args.mask;
    // if (args.maxlength) this.maxlength = args.maxlength;
    // if (args.minlength) this.minlength = args.minlength;
    // if (args.checkargs) this.checkargs = args.checkargs;
    this.type = args.type || 'text';
    // if (args.date && args.date === 'yes') this.type = 'date';
    // if (args.check && args.check === 'phone') this.type = 'tel';
    // if (args.check && args.check === 'email') this.type = 'email';
    // if (args.check && latinpatterns.includes(args.check)) {
    //   this.setLatinSettings();
    // }
    // if (args.check && args.check === 'daterange') {
    //   this.setDaterangeSettings();
    // }
    // if (args.check && args.check === 'date' && !args.checkargs) {
    //   this.setDefaultDateSettings();
    // }
  }

  // get checkargsArr() {
  //   if (!this.checkargs) return [];
  //   return this.checkargs.split(',');
  // }

  // getLatinPattern() {
  //   let sym = 'a-zA-Z "';
  //   let sym = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\\-\\"\\s';
  //   let sym = '0-9a-zA-Z\\-\\"\\s';
  //   let sym = '^[a-zA-Z0-9\\"\\s]';
  // let qmin = this.minlength ? this.minLength : this.required ? '1' : '0';
  // let qmax = this.maxlength ? this.maxlength : '';
  // if (this.checkargs) {
  //   let arr = this.checkargsArr;
  //   if (arr && arr[0]) sym = getSanitizedStr(arr[0]);
  //   if (arr && arr[1]) qmin = arr[1];
  //   if (arr && arr[2]) qmax = arr[2];
  // }
  // return `[${sym}]{${qmin},${qmax}}`;
  // }

  // setLatinSettings() {
  //   this.pattern = this.getLatinPattern();
  //   if (this.checkargsArr[1]) this.minlength = this.checkargsArr[1];
  //   if (this.checkargsArr[2]) this.maxlength = this.checkargsArr[2];
  // }

  // setDaterangeSettings() {
  //   const now = new Date().toISOString().split('T')[0];
  //   let min = this.checkargsArr[0];
  //   if (min) {
  //     if (min === 'now') min = now;
  //     this.min = min;
  //   }
  //   let max = this.checkargsArr[1];
  //   if (max) {
  //     if (max === 'now') max = now;
  //     this.max = max;
  //   }
  // }

  // setDefaultDateSettings() {
  //   // fix max date
  //   if (this.mask === '9999-99-99') this.max = '9999-12-31';
  // }
}
