<template>
  <div class="allowed-users">
    <transition name="fade" mode="out-in">
      <base-loader v-if="loading || isLoading" class="allowed-users__loader" />
      <card-block v-else :title="$t('title')" class="allowed-users__block">
        <transition name="fade" mode="out-in">
          <base-table
            v-if="isReady"
            :columns="tableHead"
            :list="users"
            class="allowed-users__table"
          >
            <allowed-users-table-row
              v-for="item in users"
              :key="item.id"
              :columns="tableHead"
              :item="item"
              :tools="tools"
              :menu-tools="menuTools"
            />
            <template #after>
              <div class="allowed-users__after">
                <div class="allowed-users__after-text standart-text">
                  {{ $t('desc') }}
                </div>
                <base-button class="allowed-users__add" @click="addNewUser">
                  {{ $t('new') }}
                </base-button>
              </div>
            </template>
          </base-table>
          <main-card v-else class="allowed-users__empty">
            <div class="allowed-users__after">
              <div class="allowed-users__after-text standart-text">
                {{ $t('empty') }}
              </div>
              <base-button class="allowed-users__add" @click="addNewUser">
                {{ $t('new') }}
              </base-button>
            </div>
          </main-card>
        </transition>
      </card-block>
    </transition>
  </div>
</template>

<script>
import notifications from '@/layouts/Support/mixins/notifications.js';
import CardBlock from '../components/CardBlock';
import MainCard from '@/components/MainCard/MainCard';
import BaseTable from '@/components/Table/BaseTable.vue';
import AllowedUsersTableRow from '../components/AllowedUsersTableRow.vue';
import AllowedUserSettingsForm from '../components/AllowedUserSettingsForm.vue';
import AllowedUserRights from '../components/AllowedUserRights.vue';
import mixin from '../../mixins/index';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
export default {
  name: 'AllowedUsers',
  components: {
    CardBlock,
    MainCard,
    BaseTable,
    AllowedUsersTableRow,
  },
  mixins: [notifications, mixin, showErrorModal /*, askUnsavedPageLeave*/],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '250px',
            maxWidth: '260px',
          },
        },
        {
          key: 'realname',
          label: this.$t('tableHead.realname'),
          style: {
            whiteSpace: 'nowrap',
            width: '200px',
            maxWidth: '230px',
          },
        },
        {
          key: 'email',
          label: this.$t('tableHead.email'),
          style: {
            width: '250px',
            maxWidth: '260px',
          },
        },
        {
          key: 'access',
          label: this.$t('tableHead.access'),
          style: {
            width: '142px',
          },
        },
        {
          key: 'state',
          label: this.$t('tableHead.state'),
          style: {
            width: '104px',
          },
        },
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      menuTools: [
        {
          key: 'edit',
          callback: this.editUser,
        },
        {
          key: 'rights',
          //disabled: true,
          callback: this.editRights,
        },
        {
          key: 'suspend',
          askSure: true,
        },
        {
          key: 'resume',
          askSure: true,
        },
        {
          key: 'delete',
          askSure: true,
          color: 'del',
        },
      ],
      settingsModal: null,
      rightsModal: null,
    };
  },
  computed: {
    users() {
      if (!this.profile || !this.profile.allowedUsers.length) return [];
      return this.profile.allowedUsers;
    },
    isReady() {
      return this.profile && this.profile.allowedUsers && this.profile.allowedUsers.length;
    },
    tools() {
      return this.$store.state.moduleProfile.tools;
    },
  },
  watch: {
    profile(val, old) {
      if (!old.id || old.id !== val.id) this.fetchAllowedUsers();
    },
    loading(val, old) {
      if (old && !val) this.fetchAllowedUsers();
    },
  },
  mounted() {
    if (this.isReady) this.isLoading = false;
    else if (!this.loading && this.profile.id && !this.isReady) {
      this.fetchAllowedUsers();
    }
  },
  methods: {
    fetchAllowedUsers() {
      this.isLoading = true;
      return this.$store
        .dispatch('moduleProfile/fetchAllowedUsers')
        .finally(() => (this.isLoading = false));
    },
    updateUser(payload) {
      return this.$store.dispatch('moduleProfile/updateAllowedUser', payload);
    },
    addNewUser() {
      this.showSettingsModal();
    },
    editUser(item) {
      this.showSettingsModal({ user: item.user });
    },
    newUser(email) {
      const user = this.users.find(i => i.email === email);
      const payload = {
        func: 'rights2.user',
        user: user,
      };
      this.editRights(payload);
    },
    showSettingsModal(props = {}) {
      let self = null;
      const that = this;
      let hasChanges = false;
      this.$modals.open({
        name: 'SettingsModal',
        size: 'big',
        closable: false,
        component: AllowedUserSettingsForm,
        closeOnBackdrop: false,
        props: props,
        on: {
          ready: val => (self.footer.confirm.props.disabled = !val),
          change: val => (hasChanges = val),
          submit: data => {
            const payload = { data };
            if (props.user) payload.id = props.user.id;
            Vue.set(self.props, 'formLoading', true);
            this.updateUser(payload)
              .then(userData => {
                if (data.default_access_allow === 'off') this.newUser(userData.id);
              })
              .then(() => this.$modals.close())
              .then(() => {
                if (data.default_access_allow === 'off')
                  setTimeout(() => this.newUser(data.email), 500);
              })
              .catch(e => {
                // console.log(e);
                this.showError(e);
                Vue.set(self.props, 'formLoading', false);
                Vue.set(self.props, 'doSubmit', false);
                if (e.type === 'value' && e.object) {
                  Vue.set(self.props, 'errors', {
                    [e.object]: { msg: e.msg, value: data[e.object] },
                  });
                }
              });
          },
        },
        onOpen: instance => (self = instance),
        onClose: () => (self = null),
        onDismiss: async () => {
          if (!hasChanges) return true;
          else {
            let res = false;
            await this.askUnsavedPageLeaveT()
              .then(() => {
                res = true;
                this.$modals.close({ name: 'PageLeave' });
                this.$modals.close({ name: 'SettingsModal' });
              })
              .catch(() => {
                res = false;
              });
            return res;
          }
        },
        footer: {
          confirm: {
            props: { title: this.$t('confirm'), disabled: true },
            on: {
              click: () => {
                Vue.set(self.props, 'doSubmit', true);
              },
            },
          },
          cancel: {
            on: { click: () => this.$modals.dismiss() },
          },
        },
      });
    },
    askUnsavedPageLeaveT(props = {}) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'PageLeave',
          ...props,
          title: !props.title ? this.$t('unsaved.title') : props.title,
          text: !props.text ? this.$t('unsaved.text') : props.text,
          onClose: () => reject(),
          onDismiss: () => reject(),
          footer: {
            confirm: {
              props: { title: this.$t('unsaved.confirm') },
              on: { click: () => resolve() },
            },
            cancel: {
              props: { title: this.$t('unsaved.cancel') },
              on: {
                click: () => {
                  this.$modals.close();
                  reject();
                },
              },
            },
          },
        });
      });
    },
    editRights(item) {
      this.$modals.open({
        name: 'Rights',
        title: this.$t('rights.title'),
        component: AllowedUserRights,
        props: { user: item.user, func: item.func },
        onOpen: instance => (this.rightsModal = instance),
        onClose: () => (this.rightsModal = null),
      });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Пользователи, у которых есть доступ к вашим продуктам или услугам",
      "desc": "Доверенные пользователи — лица, которым вы доверяете доступ к вашему личному кабинету. \r\nЭто может быть полезно, когда, например, несколько человек управляет серверами или доменами вашего аккаунта.\r\nВы можете управлять их правами доступа к функциональности личного кабинета.",
      "empty": "Здесь вы можете добавить пользователей, которым будет предоставлен доступ в личный кабинет. Для каждого пользователя можно настроить индивидуальные права доступа.",
      "tableHead": {
        "name": "Имя пользователя",
        "realname": "ФИО или название",
        "email": "Email",
        "access": "Полный доступ",
        "state": "Статус"
      },
      "new": "Добавить пользователя",
      "rights": {
        "title": "Права доступа доверенного пользователя"
      },
      "unsaved": {
        "text": "Похоже, у вас остались несохраненные изменения. Если вы закроете окно с формой, они будут потеряны. Вы уверены, что хотите закрыть окно?",
        "confirm": "Закрыть"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-users {
  &__table {
    margin-bottom: 1.25rem;
  }
  &__after {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-start);
    }
    &-text {
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-right: 1.5rem;
        margin-bottom: 0;
      }
    }
  }
  &__empty {
    max-width: 50rem;
  }
  &__add {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
      flex: 0 0 auto;
    }
  }
}
</style>
