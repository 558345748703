<template>
  <div class="base-input" :class="classes">
    <div v-if="$slots.before" class="base-input__before">
      <slot name="before"></slot>
    </div>
    <div class="base-input__field-wrapper">
      <label
        v-if="label || $slots.default"
        :for="internalId"
        class="base-input__label standart-title"
      >
        <span class="base-input__label-text">
          <slot>{{ label + ':' }}</slot>
        </span>
        <span v-if="required" class="base-input__label-asterisk">*</span>
        <plain-button
          v-if="hint"
          v-tooltip="{
            content: hint,
            placement: 'top-start',
            container: false, //по другому не работает
          }"
          icon="help"
          size="small"
          color="dim"
          tabindex="-1"
          class="base-input__hint"
        />
        <span v-if="$slots.labelEnd" class="base-input__label-end standart-text">
          <slot name="labelEnd" />
        </span>
      </label>
      <div class="base-input__field">
        <div v-if="$slots.fieldBefore" class="base-input__field-before">
          <slot name="fieldBefore" />
        </div>
        <div class="base-input__field-inner">
          <component
            :is="tag"
            :id="internalId"
            ref="input"
            :readonly="readonly"
            :disabled="disabled"
            :info="info"
            :required="required"
            :pattern="pattern"
            :class="size === 'small' ? 'base-input-small__input' : 'base-input__input'"
            :value="value"
            cols="10"
            v-bind="inputAttrs"
            :placeholder="placeholder"
            v-on="inputListeners"
          >
            {{ tag === 'textarea' ? value : '' }}
          </component>

          <div
            v-if="(!hasError || !currentError) && !tooltip && (icon || $slots.icon)"
            class="base-input__icon-wrapper"
          >
            <div v-if="$slots.icon" class="base-input__icon">
              <slot name="icon" />
            </div>
            <svg-icon v-else :name="icon" class="base-input__icon" />
          </div>

          <div
            v-if="!currentError && tooltip"
            v-tooltip="tooltipOptions"
            class="base-input__icon-wrapper"
          >
            <!--            <div v-if="$slots.icon" class="base-input__icon">-->
            <div v-if="$slots.icon" class="base-input__icon">
              <slot name="icon" />
            </div>
            <svg-icon v-else name="help" class="base-input__icon" />
          </div>

          <div
            v-if="(hasError || currentError) && !$slots.icon"
            class="base-input__error-icon-wrapper"
          >
            <svg-icon name="error" class="base-input__icon" />
          </div>
        </div>
        <div v-if="$slots.fieldAfter" class="base-input__field-after">
          <slot name="fieldAfter" />
        </div>
      </div>
      <div class="base-input__error-message">
        <div v-if="hasError || currentError">
          {{ errorMessage }}
        </div>
        <div v-if="showWarning" class="base-input__email-warning">
          {{ errorMessages.email.emailWarning }}
        </div>
        <div v-if="$slots.afterLeft">
          <slot name="afterLeft"></slot>
        </div>
      </div>
      <div v-if="$slots.storageError" class="base-input__error-message">
        <slot name="storageError" />
      </div>
    </div>
    <div v-if="$slots.after" class="base-input__after">
      <slot name="after"></slot>
    </div>
    <div class="base-input__after-block">
      <div v-if="$slots.afterRight" class="base-input__after-right">
        <slot name="afterRight"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Inputmask from 'inputmask';
export default {
  name: 'BaseInput',

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'text',
      validator: value => {
        return [
          'text',
          'url',
          'email',
          'tel',
          'password',
          'number',
          'ip',
          'date',
          'msg',
          'hidden',
          'textarea',
          'fake-password',
        ].includes(value);
      },
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    customErrorMessages: {
      type: Object,
      default: null,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    customValidationRules: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: 'default',
      validator: value => {
        return ['default', 'plain'].includes(value);
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: [String, Boolean],
      default: null,
    },
    mask: {
      type: [String, Object],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    useReactiveValidation: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
    useBeforeValidation: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'base',
      validator: value => {
        return ['base', 'small'].includes(value);
      },
    },
  },

  data() {
    return {
      internalId: this.id || `uid_${(~~(Math.random() * 1e8)).toString(16)}`,
      hasError: false,
      // lastPattern: null,
      currentError: false,
      errorMessage: '',
      errorTypes: [
        'valueMissing',
        'typeMismatch',
        'emailWarning',
        'patternMismatch',
        'tooLong',
        'tooShort',
        'rangeUnderflow',
        'rangeOverflow',
        'stepMismatch',
        'badInput',
        'customError',
      ],
      errorMessages: {
        defaults: {
          valueMissing: 'Обязательно заполните это поле',
          tooLong: 'Много',
          tooShort: 'Мало',
        },
        email: {
          typeMismatch: 'Такой почты не существует',
          emailWarning: 'Не рекомендуем указывать e-mail на вашем домене, размещаемом у нас.',
        },
        textarea: { patternMismatch: 'Введен неправильный формат' },
        tel: {
          patternMismatch: 'Номер телефона не соответствует формату',
        },
      },
      inputMaskInst: null,
      inputmaskOpts: {
        showMaskOnFocus: true,
        showMaskOnHover: false,
        clearMaskOnLostFocus: true,
        removeMaskOnSubmit: true,
        autoUnmask: true,
        onUnMask: function (maskedValue, unmaskedValue) {
          // fix inputmask's clipping '+' when unmask
          const reg = /^\+/;
          if (reg.test(maskedValue) && !reg.test(unmaskedValue)) return '+' + unmaskedValue;
          return unmaskedValue;
        },
      },
    };
  },

  computed: {
    inputListeners() {
      // eslint-disable-next-line
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: function (event) {
          // console.log('input....', event);
          vm.onInput(event);
        },
        change: function (event) {
          // console.log('change....', event);
          vm.onChange(event);
        },
        focus: function (event) {
          vm.onFocus(event);
        },
        blur: function (event) {
          // console.log('blur....', event);
          vm.onBlur(event);
        },
      });
    },
    inputAttrs() {
      const obj = { ...this.$attrs };
      if (this.type !== 'textarea' && this.type !== 'fake-password') obj.type = this.type;
      if (this.type === 'fake-password') obj.type = 'text';
      return obj;
    },
    tag() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
    classes() {
      const prefix = this.size === 'small' ? 'base-input-small' : 'base-input';
      return {
        [`${prefix}--filled`]: this.value && this.value.length,
        [`${prefix}--has-error`]: this.useBeforeValidation
          ? (this.hasError && this.errorMessage) || this.invalid
          : this.hasError || this.invalid,
        [`${prefix}--${this.theme}`]: !!this.theme,
        [`${prefix}--has-icon`]: this.icon,
        [`${prefix}--disabled`]: this.disabled,
        [`${prefix}--readonly`]: this.readonly,
        [`${prefix}--info`]: this.info,
        [`${prefix}--${this.type}`]: !!this.type,
      };
    },
    tooltipOptions() {
      if (!this.tooltip) return '';
      return {
        content: this.tooltip,
        placement: 'top-end',
        classes: 'standart-text base-input-tooltip',
        trigget: 'hover click focus',
        container: false,
      };
    },
  },

  watch: {
    value: {
      handler: function (value) {
        if (this.$refs?.input) this.$refs.input.style.height = 'auto';
        if (this.$refs?.input && this.$refs.input.scrollHeight)
          this.$nextTick(() => {
            this.$refs.input.style.height = this.$refs.input.scrollHeight + 'px';
          });
        if (this.$refs?.input && value != this.$refs.input.value) this.$refs.input.value = value;
      },
      immediate: false,
    },
    pattern: {
      handler: function (val) {
        // console.log('.............pattern');
        if (val) {
          if (this.useBeforeValidation && val) {
            if (this.$refs && this.$refs.input && this.$refs.input.pattern)
              this.$refs.input.pattern = val;
            // console.log('pattern....0');
            if (this.value && this.$refs && this.$refs.input) {
              // console.log('pattern....1');
              this.$refs.input.dispatchEvent(new Event('input'));
              this.$refs.input.dispatchEvent(new Event('change'));
            }
          } else {
            // console.log('pattern....2');
            if (this.$refs && this.$refs.input) this.$refs.input.pattern = val;
            if (this.value && this.$refs && this.$refs.input) {
              // console.log('pattern....3');
              this.$refs.input.dispatchEvent(new Event('input'));
              this.$refs.input.dispatchEvent(new Event('change'));
            }
          }
        }
      },
      immediate: true,
    },
    mask: {
      handler: function (value) {
        // console.log(value);
        if (value) this.initInputMask();
        // else if (this.$refs?.input.inputmask) this.$refs.input.inputmask.remove();
      },
      immediate: true,
    },
    type(val) {
      if (val === 'textarea' && this.$refs?.input) this.fixTextarea();
    },
    currentError(event) {
      if (event) {
        // console.log('currentError', event, this.customErrorMessages);
        this.errorMessage = this.customErrorMessages?.patternMismatch;
        // console.log(this.errorMessage);
        this.$emit('current-error', { type: this.type, check: true });
        this.hasError = event;
      } else this.$emit('current-error', { type: this.type, check: false });
    },
  },

  beforeMount() {
    if (this.customErrorMessages) {
      if (!this.errorMessages[this.type]) {
        this.errorMessages[this.type] = {};
      }
      this.errorMessages[this.type].custom = { ...this.customErrorMessages };
    }
  },

  mounted() {
    if (this.useBeforeValidation) this.$refs.input.dispatchEvent(new Event('input'));
    if (this.mask) this.initInputMask();
    if (this.type === 'textarea' && this.$refs?.input) this.fixTextarea();
  },

  destroyed() {
    this.$emit('destroy');
  },

  methods: {
    onFocus() {
      if (this.mask) {
        if (!this.inputMaskInst) this.initInputMask();
        this.inputMaskInst.mask(this.$refs.input);
      }
    },
    onBlur(event) {
      // if (this.mask && this.$refs.input.inputmask) this.$refs.input.inputmask.remove();
      // console.log('onBlur', event);
      this.onChange(event);
    },
    initInputMask() {
      if (this.mask && typeof this.mask === 'string') this.inputmaskOpts.mask = this.mask;
      else Object.assign(this.inputmaskOpts, this.mask);
      this.inputMaskInst = new Inputmask(this.inputmaskOpts);
    },
    fixTextarea() {
      if (this.$refs && this.$refs.input && this.$refs.input.innerHTML)
        setTimeout(
          () => (this.$refs.input.innerHTML = this.value ? this.value.trim() : this.value),
          100
        );
    },
    onInput(event) {
      this.$emit('input', event.target.value);
      if (this.useReactiveValidation) this.onValidateInput(event);
    },
    onValidateInput(event) {
      // console.log(event);
      // console.log('onValidateInput', event.target.validity.valid);
      // console.log(this.pattern === this.value);
      this.validate(event)
        .then(data => {
          if (this.useBeforeValidation) {
            if (data.target.validity.valid && this.pattern && this.pattern === this.value) {
              this.hasError = false;
            } else if (data.target.validity.valid && !this.pattern && this.value) {
              this.hasError = false;
              this.$emit('validate-input', event);
            }
          } else {
            this.hasError = false;
          }
        })
        .catch(event => {
          // console.log('------');
          if (this.useBeforeValidation) this.onError(event, this.hasError);
          else this.onError(event);
        })
        .finally(() => this.$emit('validate-input', event));
    },
    onChange(event) {
      // console.log('onChange.....', event);
      this.validate(event)
        .then(() => {
          this.hasError = false;
          if (this.useBeforeValidation) this.currentError = false;
        })
        .catch(event => {
          // console.log('|||||||');
          if (this.useBeforeValidation) this.onError(event, this.hasError);
          else this.onError(event);
        })
        .finally(() => this.$emit('change', event));
    },
    validate(event) {
      // console.log('type', this.type);
      // console.log('useReactiveValidation', this.useReactiveValidation);
      // console.log('pattern', this.pattern);
      // console.log('value', this.value);
      // console.log(
      //   '!validate!',
      //   'type:',
      //   this.type,
      //   'pattern:',
      //   this.pattern,
      //   'value:',
      //   this.value,
      //   'pattern===value:',
      //   this.pattern === this.value
      // );
      if (
        this.type === 'number' &&
        this.useReactiveValidation &&
        this.pattern &&
        this.pattern !== this.value
      ) {
        this.hasError = true;
        this.currentError = true;

        return Promise.reject(event);
      } else if (
        this.type === 'textarea' &&
        this.useReactiveValidation &&
        this.pattern &&
        this.pattern !== this.value
      ) {
        this.hasError = true;
        this.currentError = true;

        return Promise.reject(event);
      } else if (
        this.type === 'text' &&
        this.useReactiveValidation &&
        this.pattern &&
        this.pattern !== this.value
      ) {
        // console.log('Promise.reject....text');
        this.hasError = true;
        this.currentError = true;

        return Promise.reject(event);
      } else if (
        this.type === 'password' &&
        this.useReactiveValidation &&
        this.pattern &&
        this.pattern !== this.value
      ) {
        this.hasError = true;
        this.currentError = true;

        return Promise.reject(event);
      } else if (event.target.validity.valid) {
        // console.log(event.target.validity);
        this.hasError = false;
        this.currentError = false;
        return Promise.resolve(event);
      } else {
        // console.log(event.target.validity.valid);
        this.hasError = true;
        this.currentError = true;
        return Promise.reject(event);
      }
    },
    onError(event, err) {
      // console.log(this.pattern, this.value, '=========', this.value === this.pattern);
      const error = this.getError(event);
      this.errorMessage = error.text;
      // console.log(this.errorMessage);
      this.hasError = !!err;
      this.$emit('error', error);
    },
    getError(event) {
      // //console.log(this.pattern, this.useReactiveValidation, this.type === 'number');
      // console.log(
      //   'pattern',
      //   this.pattern,
      //   'value',
      //   this.value,
      //   this.pattern === this.value,
      //   this.useReactiveValidation,
      //   this.type,
      //   event.target.validationMessage,
      //   event.target.validity
      //   this.customErrorMessages.confirm.patternMismatch
      // );
      const error = {
        event: event,
        type: '',
        text:
          this.type === 'textarea' && this.useReactiveValidation
            ? 'не выполнены условия'
            : event.target.validationMessage,
      };
      if (this.pattern === this.value) {
        this.hasError = false;
        this.currentError = false;
        // console.log('//////////////////////');
        return false;
      }
      if (this.pattern !== this.value && this.useReactiveValidation && this.type === 'number') {
        error.type = 'typeMismatch';
        error.text = this.customErrorMessages.confirm.patternMismatch;
      }
      if (
        this.pattern !== this.value &&
        this.useReactiveValidation &&
        this.type === 'text' &&
        !!this.customErrorMessages &&
        !!this.customErrorMessages.confirm &&
        !!this.customErrorMessages.confirm.patternMismatch
      ) {
        error.type = 'typeMismatch';
        error.text = this.customErrorMessages.confirm.patternMismatch;
      }
      if (this.pattern !== this.value && this.useReactiveValidation && this.type === 'ip') {
        error.type = 'typeMismatch';
        error.text = this.customErrorMessages.confirm.patternMismatch;
      }
      if (this.pattern === 'tooMach' && this.useReactiveValidation) {
        error.type = 'tooMach';
        error.text = this.customErrorMessages.confirm.patternMismatch;
      }
      for (const item of this.errorTypes) {
        if (event.target.validity[item]) {
          error.type = item;
        }
      }
      if (
        this.errorMessages[this.type] &&
        this.errorMessages[this.type].custom &&
        this.errorMessages[this.type].custom[error.type]
      ) {
        // console.log('1');
        error.text = this.errorMessages[this.type].custom[error.type];
      } else if (this.errorMessages[this.type] && this.errorMessages[this.type][error.type]) {
        // console.log('2');
        error.text = this.errorMessages[this.type][error.type];
      } else if (this.errorMessages.defaults[error.type]) {
        // console.log('3');
        error.text = this.errorMessages.defaults[error.type];
      }
      if (event.target && event.target.validity && event.target.validity.valid && error.type) {
        // console.log('4');
        this.currentError = false;
        this.hasError = false;
      }
      return error;
    },
    clearErrorMessage() {
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-input {
  max-width: 100%;

  &__before {
    margin-bottom: 0.6em;
  }
  &__after {
    margin-top: 0.6em;
  }
  &__after-right {
    line-height: 20px;
    font-size: 14px;
  }
  &__after-block {
    display: flex;
    justify-content: flex-end;
    //margin-top: 0.6em;
  }
  &__after-error {
    font-size: 12px;
    line-height: 16px;
    color: #ee9494;
  }
  &__field-wrapper,
  &__field-inner {
    position: relative;
  }

  &__field {
    flexy(flex-start, center);

    &-before,
    &-after {
      flex: 0 0 auto;
      margin-left: 0.25rem;
    }
    &-inner {
      flex: 1 1 100%;
    }
  }

  &__label {
    flexy(flex-start, center);
    margin-bottom: 10px;
    color: var(--text);
    transition: color $anim-base-duration ease, opacity $anim-base-duration ease;

    ^[0]--info &-text {
      opacity: 0.9;
    }

    ^[0]--disabled &-text,
    ^[0]--readonly &-text {
      //opacity: 0.4;
    }

    &-asterisk {
      //color: $error-color;
      color: $color-red.base;
      font-size: large;
      margin-left: 0.125rem;
    }
    &-end {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__input {
    display: block;
    width: 100%;
    //height: 100%
    height: 50px
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: var(--input-color);
    background: transparent;
    padding: 1em 1rem;
    border: 1px solid var(--input-border);
    box-shadow: none;
    box-sizing: border-box;
    appearance: none;
    transition: all 0.3s;
    border-radius: $border-radius-small;

    &[type="date"]::-webkit-clear-button,
    &[type="date"]::-webkit-inner-spin-button {
      display: none;
    }
    &[type="number"]::-webkit-inner-spin-button {
      display: none;
    }

    textarea& {
      //height: 11em;
      min-height: 8em;
      max-height: 45em;
      //max-height: auto;
      //resize: none;
      //resize: auto;
      resize: vertical;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &::placeholder {
      color: var(--input-color);
      opacity: 0.5;
      transition: color $anim-base-duration ease;
    }

    &:hover, &:focus, &:active {
      outline: 0;
    }

    &:focus {
      border-color: var(--input-border-focused);
    }

    &:disabled,
    &:info,
    &[readonly] {
      color: var(--input-border);
      border-color: var(--input-border);
      cursor: auto;
    }

    ^[0]--has-error & {
      border-color: $error-color;
    }

    ^[0]--has-error^[0]--date &,
    ^[0]--has-error^[0]--datetime &,
    ^[0]--has-error^[0]--time & {
      padding-right: 2.5rem;
    }

    ^[0]--fake-password & {
      font-family: 'DotsFont';
      font-size: 0.5rem;
      line-height: $line-height-base;
      height: ($font-size-base * 2 + $line-height-base + 2);
      // unsupported by fucking mozilla bleat'
      //text-security: disc;
      //-webkit-text-security: disc;
      //-moz-text-security: disc;
    }
  }

  &-small {
    display: block;
    width: 100%;
    //height: 100%
    height: 36px
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: var(--input-color);
    background: transparent;
    padding: 1em 1rem;
    border: 1px solid var(--input-border);
    box-shadow: none;
    box-sizing: border-box;
    appearance: none;
    transition: all 0.3s;
    border-radius: $border-radius-small;

    &__input {
      height: 36px;
      width: 100%;
      border: 1px solid var(--input-border);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 15px 24px 15px 15px;
      min-width: 128px;
      background: var(--login-background-input);
      color: var(--login-color-input);

      &:focus {
        outline: none;
      }
    }

    &[type="date"]::-webkit-clear-button,
    &[type="date"]::-webkit-inner-spin-button {
      display: none;
    }
    &[type="number"]::-webkit-inner-spin-button {
      display: none;
    }

    textarea& {
      //height: 11em;
      min-height: 15em;
      max-height: 45em;
      //max-height: auto;
      //resize: none;
      //resize: auto;
      resize: vertical;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &::placeholder {
      color: var(--input-color);
      opacity: 0.5;
      transition: color $anim-base-duration ease;
    }

    &:hover, &:focus, &:active {
      outline: 0;
    }

    &:focus {
      border-color: var(--input-border-focused);
    }

    &:disabled,
    &:info,
    &[readonly] {
      color: var(--input-border);
      border-color: var(--input-border);
      cursor: auto;
    }

    ^[0]--has-error & {
      border-color: $error-color;
    }

    ^[0]--has-error^[0]--date &,
    ^[0]--has-error^[0]--datetime &,
    ^[0]--has-error^[0]--time & {
      padding-right: 2.5rem;
    }

    ^[0]--fake-password & {
      font-family: 'DotsFont';
      font-size: 0.5rem;
      line-height: $line-height-base;
      height: ($font-size-base * 2 + $line-height-base + 2);
      // unsupported by fucking mozilla bleat'
      //text-security: disc;
      //-webkit-text-security: disc;
      //-moz-text-security: disc;
    }
  }

  &__icon-wrapper {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    bottom: 14px;
    right: 14px;
  }


  &__icon {
    font-size: 1.5rem;
    color: var(--input-border)
  }

  &__error-message {
    margin-top: 0.25rem;
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $error-color;

    +breakpoint(sm-and-up) {
      position: absolute;
    }
  }

  &__email-warning {
    color: $color-dark.medium;

  }

  &__error-icon-wrapper {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    bottom: 14px;
    right: 14px;

    & ^[0]__icon {
      color: $error-color;
    }
  }

  &--has-icon {
    & ^[0]__input {
      padding-right: 40px;
    }
  }

  &__hint {
    margin-left: 0.25rem!important;
    //align-self: center;
    vertical-align: middle;
  }
}
</style>

<style lang="stylus">
.base-input-tooltip {
  max-width: 25rem;
}
</style>
