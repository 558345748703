import billMgrToolActions from './billMgrToolActions';
import { isEmpty } from 'lodash';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import Vue from 'vue';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updatePayers from '@/mixins/updatePayers';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import qs from 'qs';
/**
 * Async stepped form generator. Customizable with override some data options
 * @mixes billMgrToolActions
 * @param {string} startFunc - required! billmgr function requests wizard
 * @param {string=} [startAction] - fetchBillMgrAction | sendBillMgrAction - first step action (get | post)
 * @param {object=} [startParams] - first step params
 * @param {number=} [startStep] - start step number. -1 by default (starts from wizard[0]).
 * @param {object=} [endParams] - last step params
 * @param {string=} [endAction] - fetchBillMgrAction | sendBillMgrAction - last step action (get | post)
 * @param {object=} [showResFunc] - functions to show result. By default - show standart modal
 * @param {function|false=} [showResFunc.success] - default - this.showResModal
 * @param {function|false=} [showResFunc.fail] - default this.showResModal
 * @param {object=} [actionsTitles] - titles for actions buttons
 * @param {object=} [actionsTitles.confirm] - titles for confirm button
 * @param {string=} [actionsTitles.confirm.start] - default 'continue'. confirm button title on first step
 * @param {string=} [actionsTitles.confirm.continue] - default 'continue'. confirm button title on middle steps
 * @param {string=} [actionsTitles.confirm.end] - default 'submit'. confirm button title on last steps
 * @param {object=} [actionsTitles.cancel] - titles for cancel button
 * @param {string=} [actionsTitles.cancel.start] - default 'cancel'. cancel button title on first step
 * @param {string=} [actionsTitles.cancel.continue] - default 'back'. cancel button title on middle steps
 * @param {string=} [actionsTitles.cancel.end] - default 'back'. cancel button title on last steps
 * @param {class=} [configuratorClass] - default BaseConfigurator - configurator class, instance (extends) of BaseConfigurator
 */
export default {
  mixins: [billMgrToolActions, handleRedirect],
  data() {
    return {
      modal: null,
      wizard: [],
      wizardData: [],
      // needToShowNotification: false,
      startFunc: '',
      skipNext: true,
      customerProfileType: [],
      startParams: null,
      paymethod: '',
      validationArray: [],
      formDataBackup: {},
      requiredFields: [],
      endParams: null,
      startAction: 'fetchBillMgrAction',
      endAction: 'sendBillMgrAction',
      res: {
        success: 'success',
        fail: 'fail',
        cancel: 'cancel',
        back: 'back',
        next: 'next',
      },
      showResFunc: {
        success: this.showResModalPay,
        fail: this.showResModalPay,
      },
      actionsTitles: {
        confirm: {
          start: this.$t('confirm'),
          continue: this.$t('confirm'),
          end: this.$t('confirm'),
          // end: this.$t('submit'),
        },
        cancel: {
          start: this.$t('cancel'),
          continue: this.$t('cancel'),
          end: this.$t('cancel'),
        },
      },
      startStep: -1,
      step: -1,
      configuratorClass: BaseConfigurator,
    };
  },
  computed: {
    payersInfo() {
      return this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST'].length > 0;
      // ? this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST'][0].id
      // : null;
    },
    typePayers() {
      return this.payersInfo
        ? this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST'][0].profiletype_orig
        : null;
    },
  },
  beforeDestroy() {
    // //console.log(this.skipNext);
    this.skipNext = false;
  },
  mounted() {
    this.updateType(); /*.then(() => console.log('mount'));*/
    // //console.log('pay');
  },
  beforeMount() {
    //console.log(this.step, this.startStep, 'beforeMount');
    this.step = this.startStep;
    this.skipNext = false;
  },
  watch: {
    skipNext(val) {
      // console.log('watchSkipNext', val);
    },
    step(event) {
      //console.log(event, 'step');
    },
    // payersInfo(event) {
    //   console.log('payersInfo', event);
    // },
    startStep(event) {
      //console.log(event, 'startStep');
    },
    isViewNotification(event) {
      //console.log(event);
    },
    // formData: {
    //   handler: function (newVal, oldVal) {
    //     //console.log(newVal, oldVal);
    //     // this.GetProducts();
    //   },
    //   // deep: true,
    //   // immediate: true,
    // },
  },
  methods: {
    updateType() {
      // //console.log('права', this.$store.state.moduleProfile.access);
      // const profileAccess = this.$store.state.moduleProfile.access;
      // //console.log('customer.profile', profileAccess['customer.profile']);
      // if (profileAccess['customer.profile']) {
      this.$store.dispatch('moduleProfile/fetchPayersInfo').catch(e => {
        !IS_PROD ? console.log(e) : null;
        // if (e.msg && e.msg.includes('clientoption')) {
        // return;
        // } else this.showError(e);
      });
      // } else return;
    },
    runWizardPay: async function (payload = {}) {
      if (payload.notification) this.needToShowNotification = true;
      // console.log(payload);
      if (payload.view) this.isViewNotification = true;
      // console.log('......startParams.........', this.startParams);
      // this.skipNext = this.startParams.skipNext === true;
      this.skipNext = !!(
        !!this.startParams &&
        this.startParams.skipNext &&
        this.startParams.skipNext === true
      );
      // this.skipNext = true;
      this.showLoadingModalPay({ closeOnBackdrop: false });

      async function* generate(ctx) {
        // console.log(ctx);
        // console.log(ctx.step, ctx.startStep);
        // console.log(ctx.step, ctx.wizard.length - 1);

        while (ctx.step === ctx.startStep || ctx.step <= ctx.wizard.length - 1) {
          // console.log(ctx);
          // console.log(ctx.step);

          yield await ctx.runStepPay();
        }
      }

      // console.log('runWizardPay');

      const stepsGen = generate(this);
      // console.log('stepsGen', stepsGen);
      let step;
      for await (step of stepsGen) {
        // console.log('----------step---------', step);
        const { data, res } = step;
        // console.log(data, res);
        // if (data && data.paymethod && this.paymethod) { //TODO
        //   data.paymethod = this.paymethod;
        //   this.paymethod = '';
        // }
        // console.log(res);
        // if (res === this.res.next) this.step++;
        if (res === this.res.next && this.skipNext) this.step++;
        else if (res === this.res.next && !this.skipNext) this.step;
        // отвечает за остановку пропусков платежных экранов, можно более изящно решить эту проблему
        else if (res === this.res.back) this.step--;
        // else if (res === this.res.success) {
        //   this.resetWizardPay();
        //   this.step++;
        //   this.$modals.close({ name: this.modal.name });
        //   // await this.handleRedirect(data.ok);
        // }
        // else if (res === this.res.back) this.step--;
        else if (res === this.res.cancel) {
          this.resetWizardPay();
          if (this.modal) this.$modals.close({ name: this.modal.name });
          break;
        } else {
          this.resetWizardPay();
          if (this.modal) this.$modals.close({ name: this.modal.name });
          break;
        }
      }
      if (step.res === this.res.success) return Promise.resolve(step.data);
      else return Promise.reject(step.data || step.res);
    },
    fetchStepDataPay(step = this.step, payload = {}, hasSv = false) {
      // console.log('fetchStepData');
      const isStart = step === this.startStep;
      // console.log(this.startStep);
      // console.log(isStart);
      const fetchStep = isStart ? step + 1 : step;
      // console.log(fetchStep);
      const isEnd = fetchStep === this.wizard.length - 1;
      // console.log(isEnd);
      const curfunc = isStart ? this.startFunc : this.wizard[step].name;
      // console.log(curfunc);
      const action = isStart ? this.startAction : isEnd ? this.endAction : 'sendBillMgrAction';
      const formData =
        this.wizardData.length && this.wizardData[fetchStep] && this.wizardData[fetchStep].formData
          ? this.wizardData[fetchStep].formData
          : {};
      // console.log('......................THIS...................');
      // console.log(formData);
      // console.log(this.wizardData.length);
      // console.log(this.wizardData[fetchStep]);
      if (isStart && this.startParams) Object.assign(formData, this.startParams);
      // console.log(this.startParams);
      if (isEnd && this.endParams) Object.assign(formData, this.endParams);
      if (!hasSv && formData.hasOwnProperty('sv_field')) delete formData.sv_field;

      const params = {
        func: curfunc,
        ...formData,
        ...payload,
        show_metadata: 'on',
      };
      // console.log(params);
      return new Promise((resolve, reject) => {
        this[action](params)
          .then(data => {
            // console.log(data);
            // if (data && data.list && data.list.methodlist && data.list.methodlist[0]) { //TODO
            // console.log();
            // console.log(data.list.methodlist[0]);
            // this.paymethod = data.list.methodlist[0].paymethod;
            // }
            // console.log('data--required', data.required);
            // this.requiredFields = Object.keys(data.required);
            this.requiredFields = data.required ? Object.keys(data.required) : [];
            // if (data.required) {
            //   this.requiredFields = Object.keys(data.required);
            // } else
            // console.log(this.requiredFields);
            if (data.error) reject({ res: this.res.fail, data });
            if (data.ok) resolve({ res: this.res.success, data });
            const { func, wizard } = data;
            if (wizard) this.wizard = wizard;
            const stepData = {
              name: func,
              requred: this.requiredFields,
              title: this.wizard[this.step + 1].msg || '',
              data,
            };
            resolve({ data: stepData, res: this.res.next });
          })
          .catch(data => {
            reject({ res: this.res.fail, data });
          });
      });
    },
    async runStepPay() {
      // console.log('------------------------runStep');
      // console.log('skipped?', this.skipNext);
      try {
        const { data: stepData, res: fetchRes } = await this.fetchStepDataPay();
        // console.log(stepData, fetchRes);
        if (fetchRes === this.res.success) return { data: stepData, res: fetchRes };
        const nextStep = this.step + 1;
        if (!this.wizardData[nextStep]) this.wizardData[nextStep] = stepData;
        else Object.assign(this.wizardData[nextStep], stepData);
        const model = this.wizardData[nextStep].formData ? this.wizardData[nextStep].formData : {};
        // console.log(stepData.data);
        const config = this.makeConfiguratorPay(stepData.data, model);
        console.log('config', config);
        if (config === this.res.fail) return { res: this.res.fail };
        else {
          // console.log('else');
          // console.log(this.wizardData[nextStep], 'config', config);
          Vue.set(this.wizardData[nextStep], 'config', config);
        }
        // this.getRequiredArray(this.wizardData[nextStep]);
        // console.log(this.wizardData[nextStep]);
        // console.log(this.formDataBackup);
        // console.log(this.requiredFields);
        // console.log(this.typePayers);
        // console.log(this.validationArray);
        const next =
          (!this.payersInfo && stepData.data.model.payment_method !== 'qiwi') ||
          (stepData.data.func === 'payment.add.country' && this.payersInfo) ||
          (stepData.data.func === 'payment.add.profile' && this.payersInfo) ||
          (stepData.data.func === 'payment.add.pay' &&
            this.payersInfo &&
            stepData.data.model.payment_method !== 'qiwi') ||
          (stepData.data.func === 'payment.stored_methods.add.add' && this.payersInfo) ||
          (stepData.data.func === 'payment.stored_methods.add.paymethod' && this.payersInfo) ||
          (stepData.data.func === 'payment.stored_methods.add.country' && this.payersInfo) ||
          (stepData.data.func === 'payment.stored_methods.add.profile' && this.payersInfo) ||
          (stepData.data.func === 'payment.recurring.add.country' && this.payersInfo) ||
          (stepData.data.func === 'payment.recurring.add.profile' && this.payersInfo);
        // console.log(stepData.data.func);
        // if (stepData.data.func === 'payment.stored_methods.add.paymethod') {
        //   console.log(stepData);
        //   console.log(next);
        //   console.log(stepData.data.func === 'payment.stored_methods.add.paymethod');
        // }
        // console.log('next:', !this.skipNext ? false : next);
        // console.log('next', next);
        // console.log(this.payersInfo);
        // console.log('старт запроса с пропуском?');
        // console.log(
        //   stepData.data.func === 'payment.add.pay',
        //   this.payersInfo,
        //   this.typePayers,
        //   stepData.data.model.payment_method !== 'qiwi'
        // );
        // console.log(next);
        const { data: formData, res: editRes } = await this.showEditModalPay({
          stepData: this.wizardData[nextStep],
          next: !this.skipNext ? false : next,
          // next: next,
        });
        // console.log('207');
        Vue.set(this.wizardData[nextStep], 'formData', formData);
        // console.log('return-------runStep');
        return { data: formData, res: editRes };
      } catch (e) {
        return e;
      }
    },
    makeModalPay(props = {}) {
      // console.log('StepperModal_WIZARD');
      this.$modals.open({
        name: 'StepperModalPay',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        ...props,
      });
    },
    showLoadingModalPay(props = {}) {
      if (!this.modal) this.makeModalPay(props);
      Vue.set(this.modal, 'text', null);
      // Vue.set(this.modal, 'title', this.$t('loading'));
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModalPay(payload) {
      // console.log('---------------------------------------------------editModal');
      const { title, config } = payload.stepData;
      // const { required } = payload.required;
      //console.log('загружаем модалку?', required);
      return new Promise((resolve, reject) => {
        if (!config || !(config instanceof BaseConfigurator)) reject({ res: this.res.fail });
        if (!this.modal) this.makeModalPay();
        const isStart = this.step === this.startStep;
        // const isEnd = this.step === this.wizard.length - 2;
        const isEnd = this.step === this.wizard.length - 1;
        let formData = {};
        let formDataBackup = null;
        if (payload.next) this.showLoadingModalPay({ closeOnBackdrop: false });
        // Vue.set(this.modal, 'title', title);
        Vue.set(this.modal, 'onDismiss', () => {
          this.$modals.close({ name: this.modal.name });
          reject({ res: this.res.cancel });
        });
        Vue.set(this.modal, 'props', {
          period: 12,
          configurator: config,
          required: this.requiredFields,
          // fullCost: this.fullCost,
          isViewNotification: this.isViewNotification,
          needToShowNotification: this.needToShowNotification,
          skip: payload.next, // && this.payersInfo,
          // payers: !this.payersInfo,
        });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            // console.log('---data---', data);
            // console.log('обязательные поля есть?', this.requiredFields);
            Object.assign(formData, data);
            formDataBackup = { ...formData };
            this.formDataBackup = { ...formData };
            // ////console.log('formDataBackup', formDataBackup);
            if (!payload.next) {
              Vue.set(this.modal.footer.confirm.props, 'loading', false);
            }
          },
          ready: data => {
            // console.log('//////////////', this.startFunc, this.formData);
            // console.log('ready', data);
            this.skipNext = true;
            formData = this.getUpdatedFormDataPay(formData, data);
            if (!payload.next) {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            }
          },
          skip: data => {
            // //console.log('skip', data);
            // //console.log('skip--next', payload.next);
            this.skipNext = false;
            // Vue.set(this.modal.props, 'disabled', false);
            Vue.set(this.modal.props, 'skip', false);
            // formData = this.getUpdatedFormData(formData, data);
            // if (!payload.next) {
            //   Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            // }
          },
          view: data => {
            this.isViewNotification = data;
            Vue.set(this.modal.props, 'isViewNotification', data);
            Vue.set(this.modal.props, 'view', data);
          },
          notready: data => {
            // //console.log('notready', data);
            // //console.log('notready');
            // //console.log('notready--next', payload.next);
            // formData = this.getUpdatedFormData(formData, data);
            // Vue.set(this.modal.footer.confirm.props, 'disabled', true); //TODO разобраться оплата юриками
            if (!payload.next) {
              // Vue.set(this.modal.footer.confirm.props, 'disabled', false);
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            }
          },
          change: data => {
            // console.log('---change---', data);
            // ////console.log('formData', formData);
            formData = this.getUpdatedFormDataPay(formData, data);

            // ////console.log('data', data);
          },
          blockingchange: async data => {
            Object.assign(formData, data);
            // //console.log('blockingchange');
            Vue.set(this.modal, 'component', BaseLoader);
            //Vue.set(this.modal, { period: 12, configurator: null });
            const step = this.step + 1;
            const params = { ...formData, sok: '' };
            // //console.log(params);
            try {
              const { data: stepData, res } = await this.fetchStepDataPay(step, params, true);
              if (stepData.data && res === this.res.next) {
                const model = { ...formData };
                if (stepData.data.model) Object.assign(model, stepData.data.model);
                const svField = params.sv_field;
                if (svField) delete params.sv_field;
                if (svField && !model[svField]) model[svField] = params[svField];
                // //console.log(this.wizardData[step].data.slist);
                // //console.log(this.wizardData[step].data && this.wizardData[step].data.slist);
                const configData =
                  this.wizardData[step].data && this.wizardData[step].data.slist
                    ? { slist: this.wizardData[step].data.slist }
                    : {};
                // //console.log('stepData', stepData.data, model, configData);
                const config = this.makeConfiguratorPay(stepData.data, model, configData);
                Vue.set(this.modal.props, 'configurator', config);
                Vue.set(this.modal, 'component', OrderConfig);
              } else reject({ res: this.res.fail });
            } catch ({ res }) {
              reject(res);
            }
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'closeOnBackdrop', false);
        if (!payload.next) {
          Vue.set(this.modal, 'footer', {
            confirm: {
              props: {
                title: this.actionsTitles.confirm[isStart ? 'start' : isEnd ? 'end' : 'continue'],
                disabled: true,
                loading: false,
              },
              on: {
                click: () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  // ////console.log('formData--click', formData);
                  resolve({ data: formData, res: this.res.next });
                },
              },
            },
            cancel: {
              props: {
                // title: this.actionsTitles.cancel[isStart ? 'start' : isEnd ? 'end' : 'continue'],
                title: this.actionsTitles.cancel[isStart ? 'start' : isEnd ? 'end' : 'confirm'],
                loading: false,
              },
              on: {
                click: () => {
                  Vue.set(this.modal.footer.cancel.props, 'loading', true);
                  reject({ res: this.res.cancel });
                  // reject({ res: isStart ? this.res.cancel : this.res.back });
                },
              },
            },
          });
        }
        if (payload.next) resolve({ data: formData, res: this.res.next });
      });
    },
    showResModalPay(res) {
      console.log('showResModalPay', res);
      if (!this.modal) this.makeModalPay();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close'), loading: false },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
    makeConfiguratorPay(data, formData = {}, configData = {}) {
      // ////console.log(data);
      if (!data) return this.res.fail;
      let customfields = data.customfields || data.fields || {};
      if (!isEmpty(formData)) {
        Object.keys(formData).forEach(i => {
          if (customfields[i]) customfields[i].value = formData[i];
        });
      }
      const hidefields = data.hidefields || [];
      const pages = data.pages || [];
      const required = data.required || {};
      // const model = data.model || {};
      const model = data.model && data.model.doc ? data.model.doc : data.model || {};
      // const slist =
      //   data.model && data.model.doc && data.model.doc.slist
      //     ? data.model.doc.slist
      //     : data.slist || {};
      const slist = data.slist || {};
      // //console.log(slist['customer_profiletype']);
      const hakProfileType = slist['customer_profiletype'];
      this.customerProfileType = hakProfileType ? hakProfileType : this.customerProfileType;
      // //console.log(
      //   data.model && data.model.doc && data.model.doc.slist ? data.model.doc.slist : '---no---',
      //   hakProfileType,
      //   this.customerProfileType
      // );
      const list = data.list || {};
      // //console.log(slist);
      // //console.log(configData.slist);
      if (configData.hidefields) hidefields.push(...configData.hidefields);
      if (configData.pages) pages.push(...configData.pages);
      // if (configData.model) Object.assign(model, configData.model);
      configData.model && data.model.doc
        ? Object.assign(configData.model, model)
        : Object.assign(model, configData.model);
      if (configData.required) Object.assign(required, configData.required);
      if (configData.slist) Object.assign(slist, configData.slist);
      // if (configData.slist) Object.assign(configData.slist, slist);
      slist['customer_profiletype'] = this.customerProfileType
        ? this.customerProfileType
        : slist['customer_profiletype'];
      // if (configData.slist) Object.assign(configData.slist, slist);
      // //console.log(slist);
      if (configData.list) Object.assign(list, configData.list);
      try {
        const config = new this.configuratorClass({
          customfields,
          model,
          slist,
          hidefields,
          pages,
          required,
          list,
        });
        // //console.log('config created', config);
        return config;
      } catch (e) {
        return this.res.fail;
      }
    },
    resetWizardPay() {
      //console.log('reset');
      // this.step = this.startStep;
      this.startStep = -1;
      this.step = -1;
      //console.log(this.step, this.startStep);
      this.wizard = [];
      this.needToShowNotification = false;
      this.wizardData = [];
    },
    getUpdatedFormDataPay(formData, data) {
      let tData = { ...formData };
      Object.assign(tData, data);
      Object.keys(data).forEach(key => {
        // //console.log(key, '===', data[key]);
        if (data[key] == null && tData.hasOwnProperty(key)) {
          delete tData[key];
        }
      });
      // hardcode
      if (tData.clicked_button && tData.clicked_button === 'fromsubaccount') {
        tData.func = 'payment.add.method';
      }
      return { ...tData };
    },
  },
};
