<template>
  <div v-if="config.check !== 'money'" class="text-block">
    <p v-html="config.value.trim() + '.'" class="standart-text" />
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import InputNumber from 'primevue/inputnumber';
import HtmlData from '@/models/base/components/htmlData';
export default {
  name: 'HtmlData',
  components: {
    /*BaseInput, InputNumber */
  },
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof HtmlData || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: null,
    },
    plid: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      refactoringCost: 0,
      isAt: this.config.value,
      isPlid: true,
      errorText: '',
    };
  },
  computed: {
    changeHint() {
      return this.hint === 'Дата в формате гггг-мм-дд' ? 'Дата в формате дд.мм.гггг' : this.hint;
    },
    changeValue: {
      get() {
        return this.config.value === '' ? null : +this.config.value;
      },
      set(value) {
        this.config.value = value === '' ? null : +value;
        // },
      },
    },

    changeConfigMoney() {
      if (this.config.check === 'money') {
        const newConfig = Object.assign({}, this.config);
        // newConfig.mask = '9{1,10}';
        newConfig.value = this.config.value.includes(',')
          ? this.config.value.replace(',', '.')
          : this.config.value;
        return newConfig;
      } else return this.config;
    },
  },
  watch: {
    'config.value': function (event) {
      this.$emit('change', this.config);
    },
  },
  mounted() {
    // this.checkAt();
  },
  methods: {
    // checkAt() {
    //   if (this.config.check === 'domainrecordname') {
    //     let regex = new RegExp(`^(?!\\s)(.*)\\.(${this.plid})\\.$|^(${this.plid})\\.$`);
    //     this.isPlid = !(this.isAt === '@' || regex.test(this.isAt));
    //
    //     if (this.isAt === '@' || regex.test(this.isAt)) {
    //       this.isPlid = this.isAt.startsWith('.') || this.isAt.includes(' ');
    //     }
    //     if (
    //       (this.isAt !== '@' && this.isAt.includes('@')) ||
    //       (this.isAt.endsWith('.') && !regex.test(this.isAt)) ||
    //       this.isAt.startsWith('.') ||
    //       this.isAt.includes(' ')
    //     ) {
    //       this.errorText = 'Неверный формат записи';
    //       this.$emit('error-text', true);
    //     } else {
    //       this.errorText = '';
    //       this.$emit('error-text', false);
    //     }
    //   } else if (this.config.check === 'subdomain' || this.config.check === 'srv_record_target') {
    //     let regex = new RegExp('^(?!\\s)(.*)\\.$');
    //     let regexError = new RegExp('^[a-zA-Z0-9_.-]*$');
    //     this.isPlid = !regex.test(this.isAt);
    //     if (!regexError.test(this.isAt) || this.isAt.startsWith('.')) {
    //       this.errorText = 'Неверный формат записи';
    //       this.$emit('error-text', true);
    //     } else {
    //       this.errorText = '';
    //       this.$emit('error-text', false);
    //     }
    //   }
    // },
    onChange(e) {
      this.config.value = e.target.value;
      this.$emit('change', this.config);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.number {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  &-top {
    margin-bottom: 0.5rem
  }
}
</style>
