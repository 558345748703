<template>
  <div v-if="skip">
    <base-loader />
  </div>
  <div v-else-if="payers">
    <label class="standart-title">незаполнен плательщик</label>
  </div>
  <div v-else-if="!isPayers.length && required.length" class="standart-text">
    {{ $t('warn.text') }}
    <plain-button color="primary" class="standart-text" @click="urlPayers()">
      {{ $t('warn.profile') }}
    </plain-button>
  </div>
  <div v-else :key="k" class="order-config">
    <div v-for="fieldset in grouppedList" :key="fieldset.name" class="order-config__fieldset">
      <div v-if="fieldset.msg" class="order-config__legend big-title">
        {{ fieldset.msg }}
      </div>
      <div
        v-for="(item, index) in fieldset.list"
        :key="index"
        class="order-config__item"
        :class="{
          ['order-config__item--msg']: item.type && item.type === 'msg',
          ['order-config__item--group']: isArray(item),
        }"
      >
        <div v-if="item.type === 'msg'" class="order-config__msg standart-text">
          {{ item.name === 'redirectwarn' ? $t('warn.redirectwarn') : item.label }}
        </div>
        <country-phone-block
          v-else-if="item.type === 'phoneGroup'"
          :key="item.phone.name"
          :phone="item.phone"
          :country="item.country"
          @change="onGroupChange"
        />
        <template v-else-if="isArray(item)">
          <component
            :is="components[addon.type]"
            v-for="addon in item"
            :key="addon.name"
            :config="addon.config"
            :intname="addon.intname"
            :label="addon.label"
            :cost="addon.cost"
            :shown-period="period"
            :hint="addon.hint"
            :class="{ small: addon.propwidth && addon.propwidth === 'yes' }"
            @change="onChange(addon, $event)"
            @input="onChange(addon, $event)"
          />
        </template>
        <component
          :is="components[item.type]"
          v-else-if="item.label === 'Операционная Система' || 'CMS'"
          :config="item.config"
          :filter="setFilter"
          :like="like"
          :lic="typeLicense"
          :label="item.label"
          :plid="plid"
          :intname="item.intname"
          :id-lic="pleskPanelId"
          :hide-root-field="needChangeRoot"
          :cost="item.cost"
          :view="view"
          :need-to-show-notification="needToShowNotification"
          :shown-period="period"
          :hint="item.hint"
          @change="onChange(item, $event)"
          @input="onChange(item, $event)"
          @check="onCheck(item, $event)"
          @error-text="getError"
        />
        <!--          :full-cost="fullCost"-->
        <component
          :is="components[item.type]"
          v-else
          :config="item.config"
          :label="item.label"
          :intname="item.intname"
          :cost="item.cost"
          :shown-period="period"
          :hint="item.hint"
          @change="onChange(item, $event)"
          @input="onChange(item, $event)"
          @check="onCheck(item, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxBlock from './components/CheckboxBlock.vue';
import SelectBlock from './components/SelectBlock.vue';
import SliderBlock from './components/SliderBlock.vue';
import TextBlock from './components/TextBlock.vue';
import HtmlData from './components/HtmlData.vue';
import CountryPhoneBlock from './components/CountryPhoneBlock.vue';
import RadioBlock from './components/RadioBlock.vue';
import MultipleBlock from './components/MultipleBlock.vue';
import ListBlock from './components/ListBlock.vue';
import AmountBlock from './components/AmountBlock.vue';
import PaymethodListBlock from './components/PaymethodListBlock.vue';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import { isArray } from 'lodash';
import Vue from 'vue';
import LinkBlock from '@/components/Configurator/components/LinkBlock';
import addPayment from '@/mixins/billing/addPayment';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import QualityConfigurator from '@/models/base/QualityConfigurator';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import log from '@/layouts/Stack/components/Log.vue';
/**
 * Configurator component
 * @prop {Number} period
 * @prop {BaseConfigurator} configurator - BaseConfigurator or any extended class
 * @emits {Event} init - component is inited, formData has filled
 * @emits {Event} ready - inited and formData is valid
 * @emits {Event} notready - formData is not valid
 * @emits {Event} change - formData is changed
 * @returns {Object} formData
 */
export default {
  name: 'OrderConfig',
  components: {
    BaseInput,
    CheckboxBlock,
    BaseLoader,
    SelectBlock,
    SliderBlock,
    TextBlock,
    HtmlData,
    CountryPhoneBlock,
    RadioBlock,
    MultipleBlock,
    ListBlock,
    LinkBlock,
    AmountBlock,
    PaymethodListBlock,
  },
  mixins: [addPayment],
  props: {
    period: {
      type: Number,
      required: true,
    },
    like: {
      type: String,
      default: '',
      // validator: val => ['like', 'dislike'].includes(val),
    },
    skip: {
      type: Boolean,
      default: false,
    },
    needToShowNotification: {
      type: Boolean,
      default: false,
    },
    fullCost: {
      type: Number,
      default: 0,
    },
    // view: {
    //   type: Boolean,
    //   default: false,
    // },
    required: {
      type: Array,
      default: () => [],
    },
    payers: { type: Boolean, default: false },
    configurator: {
      type: BaseConfigurator,
      required: true,
      validator: inst => inst instanceof BaseConfigurator || inst instanceof QualityConfigurator,
    },
  },
  data() {
    return {
      components: {
        select: SelectBlock,
        checkbox: CheckboxBlock,
        slider: SliderBlock,
        text: TextBlock,
        htmldata: HtmlData,
        textarea: TextBlock,
        radio: RadioBlock,
        multiple: MultipleBlock,
        list: ListBlock,
        amount: AmountBlock,
        paymethodlist: PaymethodListBlock,
        link: LinkBlock,
      },
      ispPanel: ['176', '177', '178', '179', '180'],
      pleskPanel: ['39', '40', '41', '53'],
      formData: {},
      needChangeRoot: '',
      isError: false,
      validationArray: [],
      filteredList: [],
      isInited: false,
      isValid: false,
      view: false,
      isState: false,
      needRefresh: false,
    };
  },
  computed: {
    plid() {
      if (
        this.configurator._info &&
        this.configurator._info.model &&
        this.configurator._info.model.plid
      ) {
        return this.configurator._info.model.plid;
      } else return '';
    },
    isPayers() {
      //return [];
      return this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST'];
    },
    notConfirmedText() {
      return !(
        this.$store.state.moduleBilling.moduleDocs.modulePayment.list.find(i => {
          return (
            (i['paymethod_module'] === 'pmruwire' || i['paymethod_module'] === 'pmsberbank') &&
            i.status_orig === '4'
          );
        }) || this.$store.state.moduleProfile.profile.need_phone_validate === false
      );
    },
    supportLabelAddonName() {
      if (
        this.configurator._addonsExt &&
        // this.provider === '3' &&
        this.configurator._addonsExt.find(addon => addon.intname === 'SupportLevel')
      ) {
        return `addon_${
          this.configurator._addonsExt.find(addon => addon.intname === 'SupportLevel').id
        }`;
      } else return '';
    },
    supportLabelId() {
      return this.formData[`${this.supportLabelAddonName}`];
    },
    pleskPanelAddonName() {
      if (
        this.configurator._addonsExt &&
        this.provider === '3' &&
        this.configurator._addonsExt.find(addon => addon.intname === 'pleskpanel')
      ) {
        return `addon_${
          this.configurator._addonsExt.find(addon => addon.intname === 'pleskpanel').id
        }`;
      } else return '';
    },
    currentPaymethod() {
      return this.formData && this.formData.paymethod ? this.formData.paymethod : null;
    },
    osAddonName() {
      if (this.configurator._addonsExt) {
        return `addon_${
          this.configurator._addonsExt.find(addon => addon.intname === 'ostemplate').id
        }`;
      } else return '';
    },
    pleskPanelId() {
      return this.provider === '3' ? this.formData[`${this.pleskPanelAddonName}`] : null;
    },

    typeLicense() {
      if (this.pleskPanelAddonName && this.pleskPanelAddonName.length > 0) {
        if (this.pleskPanel.includes(this.formData[this.pleskPanelAddonName])) {
          return 'Plesk';
        } else if (this.ispPanel.includes(this.formData[this.pleskPanelAddonName])) {
          return 'ISP';
        } else return 'none';
      } else return 'none';
    },
    list() {
      const list = this.configurator.configurator;
      if (!!this.isState) delete list[19];
      return list;
    },
    hidefields() {
      return this.configurator.hidefields;
    },
    pages() {
      return this.configurator.pages;
    },
    shownPages() {
      const list = this.pages.reduce((acc, p) => {
        const fields = p.fields.reduce((facc, arr) => {
          const addons = arr.reduce((aacc, f, i) => {
            const addon = this.shownList.find(a => a.name === f);

            if (addon && (addon.type !== 'msg' || (addon.type === 'msg' && i === 0))) {
              // хардкод для региона
              if (addon.type === 'select' && addon.config.value.length === 0) {
              } else if (
                addon.type === 'checkbox' &&
                addon.name.includes('offer_') &&
                addon.config.disabled &&
                addon.config.value === 'off'
              ) {
                //даем выбрать оффер если он выключен
                const addonWithOffer = addon;
                addonWithOffer.config.disabled = false;
                aacc.push(addonWithOffer);
              } else aacc.push(addon);
            }
            return aacc;
          }, []);
          if (addons.length) facc.push(...addons);
          return facc;
        }, []);
        if (fields.length) acc.push({ ...p, addons: fields });
        return acc;
      }, []);
      if (list.length) return list;
      else return [{ name: 'single', addons: this.shownList }];
    },
    grouppedList() {
      return this.shownPages.map(p => {
        const prefixed = p.addons.filter(addon => !!addon.prefixselect);
        const obj = { ...p, list: [...p.addons] };
        if (!prefixed.length && obj.name === 'physical_address') {
          const list =
            this.formData['legaleqphysical'] === 'on' &&
            (this.formData['profiletype'] === '2' || this.formData['profiletype'] === '3')
              ? [...p.addons].filter(
                  addon => addon.name === 'legaleqphysical' //|| addon.name === 'country_physical'
                )
              : [...p.addons];
          return { ...p, list: list };
        } else if (!prefixed.length && obj.name !== 'physical_address') {
          return { ...p, list: [...p.addons] };
        } else {
          const list = [...p.addons];
          prefixed.forEach(addon => {
            const corrIndex = list.findIndex(corrAddon => corrAddon.name === addon.prefixselect);
            if (~corrIndex) {
              const corrAddon = list.splice(corrIndex, 1)[0];
              const itemIndex = list.findIndex(itemAddon => itemAddon.name === addon.name);
              const insert =
                addon.check === 'phone' && corrAddon.name === `${addon.name}_country`
                  ? { type: 'phoneGroup', country: corrAddon, phone: addon }
                  : [corrAddon, addon];
              list.splice(itemIndex, 1, insert);
            }
          });

          return { ...p, list };
        }
      });
    },
    shownList() {
      // хардкод для скрытия идентификатора и адреса плеск сервера
      if (this.filteredList.find(x => x.intname === 'ip')) {
        this.filteredList.find(x => x.intname === 'ip').config.placeholder = '';
      } else if (this.filteredList.find(x => x.intname === 'period' && x.type === 'select')) {
        this.filteredList.find(x => x.intname === 'period').type = 'hidden';
      }
      return (
        this.filteredList
          .filter(addon => addon.type !== 'hidden')
          .filter(
            type =>
              !['slist_user_tickets', 'slist_user_emails', 'slist_user_items'].includes(
                type.intname
              )
          )
          .filter(x => x.label !== 'Идентификатор')
          // .filter(x => x.intname !== 'panelid')
          .filter(x => x.label !== 'Адрес Plesk Сервера')
      );
      // .filter(x => x.intname !== 'Адрес Plesk Сервера');
    },
    blockingvalues() {
      return this.configurator.blockingvalues;
    },
    k() {
      return `oc-${this.configurator.stamp}`;
    },
    provider() {
      return this.$store.state.moduleProviders.current
        ? this.$store.state.moduleProviders.current
        : null;
    },
    setFilter() {
      return this.pleskPanelId ? this.pleskPanelId !== '38' && this.provider === '3' : false;
    },
  },
  watch: {
    isValid(val) {
      // console.log('', val);
      // console.log(this.validationArray.filter(x => !x.isValid));
      if (this.required && this.required.length) {
        if (this.validationArray.filter(x => !x.isValid).length) {
          this.isValid = false;
          // console.log('not');
          this.$emit('skip', true);
          this.$emit('notready');
        } else {
          // console.log('ready');
          // console.log('----ready------', this.formData);
          this.$emit('ready', this.formData);
        }
      } else {
        // console.log('else');
        if (val) {
          // console.log(val);
          // console.log('----ready------');
          this.$emit('ready', this.formData);
        } else {
          // console.log('not val');
          this.isValid = false;
          this.$emit('notready');
        }
      }
    },
    validationArray(val) {
      if (val.length) {
        if (val.filter(x => !x.isValid).length) {
          if (
            this.formData['legaleqphysical'] === 'on' &&
            (this.formData['profiletype'] === '2' || this.formData['profiletype'] === '3')
          ) {
            // console.log('1');
            const newArray = val.filter(x => !x.isValid);
            if (newArray.filter(x => !x.field.includes('_physical')).length) {
              // console.log('1');
              this.isValid = false;
              return this.$emit('notready');
            } else {
              // console.log('----ready------');
              return this.$emit('ready', this.formData);
            }
          } else this.isValid = false;
          return this.$emit('notready');
        } else {
          // console.log('----ready------');
          // this.$emit('ready', this.formData);
          if (!this.isPayers.length && this.required.length) {
            this.$emit('notready');
          } else this.$emit('ready', this.formData);
        }
      }
    },
    configurator(val, old) {
      if (val !== old) {
        this.inited = false;
        this.$nextTick(this.init);
      }
    },
    // view(val, old) {
    // console.log(val, old);
    // },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.isInited = false;
    this.$emit('destroy');
  },
  methods: {
    urlPayers() {
      this.$router.push({ name: 'BillingPayers' }).catch(() => {});
      return this.$modals.close('StepperModalPay');
    },
    pay() {
      this.startParams = {
        billorder: this.formData.billorder,
      };
      this.runWizardPay()
        .then(data => {
          // console.log(data);
          this.handleRedirect(data.ok);
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.updateBalance();
          // this.$gtm.trackEvent({
          //   event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
          //   category: 'pay_method',
          //   action: 'click',
          //   label: 'pay',
          //   value: 5000,
          //   noninteraction: false, // Optional
          // });
        })
        .catch(() => {});
    },
    init() {
      // console.log('init');
      // if (addon.intname === 'SupportLevel') {
      //   this.needChangeRoot = event.value; //supportLevel new vps
      // }
      this.formData = {};
      this.list.forEach(addon => {
        if (addon.formDataNames) {
          addon.formDataNames.forEach(name => {
            // console.log('******', this.formData.hasOwnProperty(name), this.formData[name]);
            if (this.formData.hasOwnProperty(name)) this.formData[name] = null;
          });
        }
        // if (addon.type !== 'hidden') this.formData[addon.formDataName] = addon.config.value;
        // console.log(addon.formDataName, addon.config.value);
        this.formData[addon.formDataName] = addon.config.value;
      });
      // console.log(this.formData);
      // console.log(this.formData['paymethod']);
      // console.log(this.formData.paymethod);
      if (this.formData['paymethod'] === 0) this.formData['paymethod'] = '';
      if (this.list.find(x => x.config.additionalStep)) {
        const findedAddon = this.list.filter(x => x.config.additionalStep)[0];
        // console.log(findedAddon);
        // console.log(this.formData[findedAddon.name]);
        this.formData[findedAddon.name] = this.formData[findedAddon.name] * 1024;
        // console.log(
        //   '!!!!!!!!!!!!!!!!!!!!!!!!!finded',
        //   this.list.find(x => x.config.additionalStep)
        // );
      }
      this.filteredList = this.getFilteredList();
      this.isInited = true;
      // console.log('initialized', this.formData, this.list);
      this.$emit('init', this.formData);
      if (this.formData['offer_2'] && this.formData['offer_2'] === 'off')
        this.formData['offer_2'] = 'on';
      if (this.formData['offer_1'] && this.formData['offer_1'] === 'off')
        this.formData['offer_1'] = 'on';
      if (this.formData['offer_3'] && this.formData['offer_3'] === 'off')
        this.formData['offer_3'] = 'on';
      if (this.formData['offer_5'] && this.formData['offer_5'] === 'off')
        this.formData['offer_5'] = 'on';
      if (this.formData[this.supportLabelAddonName]) {
        this.needChangeRoot = this.formData[this.supportLabelAddonName];
        // console.log('init', this.formData);
        this.$emit('change', this.formData);
      }
      // console.log(this.formData['offer_2']);
      // if (this.formData['offer_2'] && this.formData['offer_2'] === 'on')
      //   this.formData['offer_2'] = 'off';
      this.validate();
      if (this.required.length) {
        if (this.validationArray.filter(x => !x.isValid).length) {
          this.$emit('notready');
        } else {
          // console.log('------ready------', this.formData);
          this.$emit('ready', this.formData);
        }
      } else {
        if (this.isValid) {
          // console.log('----ready------', this.formData);
          this.$emit('ready', this.formData);
        }
      }
    },
    onCheck(addon, event) {
      const value = event instanceof Event ? event.target.value : event.value || event.total_sum;
      if (addon.formDataNames) {
        addon.formDataNames.forEach(name => {
          if (this.formData.hasOwnProperty(name)) this.formData[name] = null;
        });
      }
      Vue.set(this.formData, addon.formDataName, value);
      this.filteredList = this.getFilteredList();
      this.validate();
      this.isInited = false;
      this.$emit('destroy');
      this.pay();
      if (addon.clicked_button) this.formData.clicked_button = addon.clicked_button;
      this.$emit('change', this.formData);
      if (addon.setvalues && this.blockingvalues.includes(addon.setvalues)) {
        this.$emit('blockingchange', { ...this.formData, sv_field: addon.formDataName });
      }
    },
    onChange(addon, event) {
      // console.log('onChange', addon, event);
      if (addon.intname === 'SupportLevel') {
        this.needChangeRoot = event.value; //supportLevel new vps
      }
      if (addon.name === this.pleskPanelAddonName) {
        this.init();
      }
      if (addon.name === 'paymethodlist' && event.value === '20') {
        this.view = true;
        this.$emit('view', true);
      } else {
        this.view = false;
        this.$emit('view', false);
      }
      // console.log(addon, event.value, addon.type);
      const value =
        event instanceof Event
          ? event.target.value
          : event &&
            (event.type === 'text' || addon.type === 'multiple' || addon.type === 'htmldata')
          ? event.value || ''
          : event.value || event.total_sum || 0;
      // console.log(value);
      if (addon.formDataNames) {
        addon.formDataNames.forEach(name => {
          if (this.formData.hasOwnProperty(name)) {
            this.formData[name] = null;
          }
        });
      }
      if (
        addon.name === 'legaleqphysical' &&
        event.value === 'on' &&
        (this.formData['profiletype'] === '2' || this.formData['profiletype'] === '3')
      ) {
        // console.log('3');
        Vue.set(this.formData, 'postcode_physical', this.formData['postcode_legal']);
        Vue.set(this.formData, 'address_physical', this.formData['address_legal']);
        Vue.set(this.formData, 'city_physical', this.formData['city_legal']);
      } else if (
        addon.name === 'legaleqphysical' &&
        event.value === 'off' &&
        (this.formData['profiletype'] === '2' || this.formData['profiletype'] === '3')
      ) {
        // console.log('4');
        Vue.set(this.formData, 'postcode_physical', '');
        Vue.set(this.formData, 'address_physical', '');
        Vue.set(this.formData, 'city_physical', '');
      }
      Vue.set(this.formData, addon.formDataName, value);
      this.filteredList = this.getFilteredList();
      // if (this.filteredList.find(x => x.intname === 'ip')) {
      //   console.log('YES......');
      //   this.filteredList.find(x => x.intname === 'ip').config.placeholder = '';
      // }
      this.validate();
      if (addon.clicked_button) this.formData.clicked_button = addon.clicked_button;
      this.$emit('change', this.formData);
      if (addon.setvalues && this.blockingvalues.includes(addon.setvalues)) {
        // console.log('------');
        this.$emit('blockingchange', { ...this.formData, sv_field: addon.formDataName });
      }
    },
    onGroupChange(payload) {
      Object.assign(this.formData, payload);
      this.filteredList = this.getFilteredList();
      this.validate();
      this.$emit('change', this.formData);
    },
    getFilteredList() {
      if (!this.hidefields.length) return this.list;
      let internal = [...this.list];
      this.hidefields.forEach(item => {
        const addonIndex = internal.findIndex(i => i.name === item.name);
        if (~addonIndex) {
          const addon = internal[addonIndex];
          const value = this.isInited ? this.formData[addon.name] : addon.config.value;
          Object.keys(item.if).forEach(key => {
            if ((key === 'noempty' && value) || (key === 'empty' && !value) || key == value) {
              item.if[key].forEach(n => {
                const hideIndex = internal.findIndex(i => i.name === n);
                if (~hideIndex) {
                  const corrAddonName = internal[hideIndex].prefixselect;
                  internal.splice(hideIndex, 1);
                  if (corrAddonName) {
                    const hideCorrIndex = internal.findIndex(i => i.name === corrAddonName);
                    if (~hideCorrIndex) internal.splice(hideCorrIndex, 1);
                  }
                }
              });
            }
          });
        }
      });
      return internal;
    },
    isArray(item) {
      return isArray(item);
    },
    validate() {
      //console.log('validate');
      if (this.required && this.required.length) {
        //console.log('1');
        const valideFields = [];
        const profileType =
          this.formData && this.formData.profiletype && this.formData.profiletype === '1'
            ? 'physical'
            : this.formData.profiletype === '2'
            ? 'legal'
            : this.formData.profiletype === '3'
            ? 'ip'
            : 'none';
        this.required.forEach(requiredField => {
          //console.log('2');
          if (this.formData[requiredField]) {
            this.isValid = true;
            valideFields.push({
              field: requiredField,
              isValid: this.isValid,
            });
          } else {
            //console.log('3');
            if (requiredField.includes('state_')) {
              //TODO state
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.admin_contact_use_first === 'on' &&
              requiredField.includes('admin_')
            ) {
              //console.log('4');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.bill_contact_use_first === 'on' &&
              requiredField.includes('bill_')
            ) {
              //console.log('5');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.tech_contact_use_first === 'on' &&
              requiredField.includes('tech_')
            ) {
              //console.log('6');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.customer_profiletype === '2' &&
              (requiredField.includes('customer_birthdate') ||
                requiredField.includes('customer_passport') ||
                requiredField.includes('customer_registration'))
            ) {
              //console.log('7');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.customer_profiletype === '2' &&
              this.formData.hide_bill_company_info === 'on' &&
              requiredField.includes('bill_company')
            ) {
              //console.log('8');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.customer_profiletype === '2' &&
              this.formData.hide_admin_company_info === 'on' &&
              requiredField.includes('admin_company')
            ) {
              //console.log('9');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.customer_profiletype === '2' &&
              this.formData.hide_tech_company_info === 'on' &&
              requiredField.includes('tech_company')
            ) {
              //console.log('10');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.customer_profiletype === '3' &&
              requiredField.includes('_company')
            ) {
              //console.log('11');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (
              this.formData.customer_profiletype === '1' &&
              (requiredField.includes('_company') ||
                requiredField.includes('_inn') ||
                requiredField.includes('_kpp') ||
                requiredField.includes('_ogrn'))
            ) {
              //console.log('12');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (requiredField.includes('alfabank_login')) {
              //console.log('13');
              this.isValid = true;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (profileType === 'physical' && requiredField.includes('_legal')) {
              this.isValid = true;
              //console.log('14');
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else if (profileType === 'physical' && requiredField.includes('name')) {
              this.isValid = true;
              //console.log('15');
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            } else {
              //console.log('16');
              this.isValid = false;
              valideFields.push({
                field: requiredField,
                isValid: this.isValid,
              });
            }
          }
          this.validationArray = valideFields;
        });
      } else if (this.isError) {
        this.isValid = false;
      } else {
        // console.log('20');
        // console.log('errorText', this.isError);
        // console.log(
        //   this.filteredList
        //     .filter(addon => addon.config.type !== 'hidden')
        //     .filter(addon => addon.config.required)
        //     .every(addon => this.isValidField(addon))
        // );
        this.isValid = this.filteredList
          .filter(addon => addon.config.type !== 'hidden')
          .filter(addon => addon.config.required)
          .every(addon => this.isValidField(addon));
      }
    },
    getError(newError) {
      this.isError = newError;
    },
    isValidField(addon) {
      const finalAddonName = addon.formDataName || addon.name;
      const value = this.formData[finalAddonName];
      // console.log(
      //   value,
      //   value && addon.type !== 'checkbox',
      //   addon.type === 'checkbox' && value !== 'off'
      // );
      return (
        (value && value !== 'null' && addon.type !== 'checkbox') ||
        (addon.type === 'checkbox' && value !== 'off') ||
        addon.name.includes('state_')
      );
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "warn": {
      "redirectwarn": "После нажатия на кнопку 'Подтвердить' будет открыт сайт платёжной системы. После оплаты вы сможете вернуться в личный кабинет для продолжения работы. Обратите внимание, что зачисление средств может произойти не мгновенно (до 1 часа), поэтому дождитесь, когда платёж перейдёт в статус 'Оплачен'.",
      "text": "Для оплаты, пожалуйста, добавьте",
      "profile": "Плательщика"
    }
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.order-config {
  &__fieldset {
    & + & {
      margin-top: 2rem;

      +breakpoint(sm-and-up) {
        margin-top: 1rem;
      }
    }
  }
  &__legend {
    margin-bottom: 1em;
  }
  &__item {
    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        //margin-top: 2.5rem;
      }
    }

    & + &--msg {
      margin-top:1rem;

      +breakpoint(sm-and-up) {
        //margin-top: 3.5rem;
      }
    }

    &--group {
      flexy(flex-start, baseline);

      .small {
        flex: 0 0 5rem;
      }
    }
  }
}

.standart-text {
  padding-left: 0;
}
</style>
