import VpsPricelist from '@/models/BillMgr/VpsPricelist';
import Vue from 'vue';
import actions from './actions';
import { StackTariff } from '@/models/BillMgr/VpsTariff';
import { VpsBackup } from '@/models/VM/VpsBackup';

/**
 * @typedef VpsOrderState
 * @prop {VpsPricelist[]} list
 * @prop {Boolean} loading
 * @prop {Number} current
 * @prop {Object} wizard - order wizard
 * @prop {String} wizard.params - func for params step
 * @prop {String} wizard.order - func for order step
 * @prop {Object} model - data-model for order
 */
const stateDef = {
  list: [],
  loading: false,
  current: null,
  wizard: { params: '', order: '' },
  model: {},
  backups: [],
  backupCount: 0,
  vpsBackupCount: 0,
  stateBackup: '',
  vpsList: [],
};
/**
 * Function returns state object
 * @returns {VpsOrderState} VpsOrderState
 */
const state = () => ({ ...stateDef });

const mutations = {
  /**
   * Add some items to list
   * @param {VpsOrderState} state - current context
   * @param {Array} items - array of items to add
   */
  addToList(state, items) {
    try {
      state.list = items.map(i => new VpsPricelist(i));
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * Set loading
   * @param {VpsOrderState} state - current context
   * @param {Boolean} value
   */
  setLoading(state, value) {
    state.loading = value;
  },
  /**
   * Set id of current item
   * @param {VpsOrderState} state - current context
   * @param {Number|null} id - id to set | null to reset
   */
  setCurrent(state, id) {
    state.current = id;
  },
  /**
   * Set some prop to VpsPricelist (by id or current)
   * @param {VpsOrderState} state - current context
   * @param {Object} payload
   * @param {String} payload.prop - prop to set
   * @param {*} payload.value - value to set
   * @param {Number=} payload.id - id of updating item, default - state.current
   */
  setItemProp(state, { prop, value, id }) {
    if (!prop || !value) throw new Error('Missed required property "prop" or "value"');
    const innerId = id || state.current;
    const instance = state.list.find(i => i.id === innerId);
    if (instance && instance instanceof VpsPricelist) {
      Vue.set(instance, prop, value);
      const autoprolondSettings = [
        { k: 'null', v: 'Отключено' },
        { k: '1', v: 'Включено' },
      ];
      if (instance._info) instance._info.slist.autoprolong = autoprolondSettings;
    }
  },
  /**
   * sSet functions for order steps from wizard
   * @param {VpsOrderState} state - current context
   * @param {Array} list - wizard functions list
   */
  setWizard(state, list) {
    if (list[0] && list[0].name) Vue.set(state.wizard, 'params', list[0].name);
    if (list[1] && list[1].name) Vue.set(state.wizard, 'order', list[1].name);
  },
  /**
   * Set model for next step from order wizard
   * @param {VpsOrderState} state - current context
   * @param {Object} value - model from order wizard
   */
  setModel(state, value) {
    state.model = value;
  },
  /**
   * reset state to default
   * @param {VpsOrderState} state - current context
   */
  resetState(state) {
    Object.keys(state).forEach(i => (state[i] = stateDef[i]));
  },
  // SET_BACKUPS_INFO: (state, payload) => {
  //   state.backups = payload;
  // },
  SET_BACKUPS_INFO: (state, list) => {
    try {
      state.backups = list.map(i => new VpsBackup(i));
    } catch (e) {
      console.error(e);
    }
  },
  setBackupCount: (state, payload) => {
    state.backupCount = payload;
  },
  setVpsBackupCount: (state, payload) => {
    state.vpsBackupCount = payload;
  },
  SET_STATE_BACKUP: (state, value) => {
    state.stateBackup = value;
  },
  setVpsList: (state, value) => {
    state.vpsList = value;
  },
  updateBackups: (state, value) => {
    state.backups = value;
  },
};

const getters = {
  current(state) {
    if (!state.current) return null;
    const instance = state.list.find(i => i.id === state.current);
    if (!~instance) return null;
    else return instance;
  },
  listBackups(state) {
    if (!state.backups) return null;
    else return state.backups;
  },
  backupCount(state) {
    if (!state.backups) return null;
    else return state.backups.length;
  },
};

const moduleStackOrder = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default moduleStackOrder;
